import { Indi } from "../../../../../libs"
import { useState } from "react"
import { ButtonContent } from "../../../../../components/ui/Button/ButtonContent"
import { Banner } from "../../../../../components/banners/Banner/Banner"
import CommunicationBg from '../../../../../assets/images/banners/event/event-diagnostic-communication-bg.jpg'
import EmotionsBg from '../../../../../assets/images/banners/event/event-diagnostic-emotions-bg.jpg'
import TravelBg from '../../../../../assets/images/banners/event/event-diagnostic-travel-bg.jpg'
import { eventsOptions } from "../../../helpers/events"
import styled, { css } from "styled-components"
import { openConsultationSignUp } from "../../../../consultation-sign-up/ConsultationSignUp"
import store from "../../../../../store/Store"
import { observer } from "mobx-react-lite"
import { gtmSend } from "../../../../../helpers/url"
import { TButtonColor } from "src/components/ui/Button/Button"

interface EventBannerProps {
  sphere: Indi.Events.Spheres
}

export const diagnostic = [Indi.Events.Spheres.COMMUNICATION, Indi.Events.Spheres.EMOTIONS, Indi.Events.Spheres.TRAVEL]
export const consultation = [Indi.Events.Spheres.LOVE, Indi.Events.Spheres.FINANCE, Indi.Events.Spheres.CAREER, Indi.Events.Spheres.HEALTH]

export const EventBanner = observer((props: EventBannerProps) => {
  const [sent, setSent] = useState(false)

  const userId = store.sessionData?.id
  const phoneNumber = store.profile.phoneNumber

  const getBannerContent = (sphere: Indi.Events.Spheres) => {
    if (consultation.includes(sphere)) {
      if (sent) {
        return {
          title: 'Заявка на консультацию отправлена!',
          text: 'Менеджер Chronos скоро с вами свяжется',
        }
      } else {
        switch (sphere) {
          case Indi.Events.Spheres.LOVE:
            return {
              title: 'Улучшите свои отношения',
              text: 'Получите индивидуальные рекомендации и улучшите взаимопонимание с партнером',
              btnTitle: 'Записаться на консультацию',
            }

          case Indi.Events.Spheres.FINANCE:
            return {
              title: 'Укрепите свою финансовую стабильность',
              text: 'Поможем вам разработать стратегию для достижения финансовой стабильности',
              btnTitle: 'Записаться на консультацию',
            }

          case Indi.Events.Spheres.CAREER:
            return {
              title: 'Получите больше от карьеры',
              text: 'Пройдите консультацию, чтобы разработать стратегию карьерного роста на основе вашей натальной карты',
              btnTitle: 'Записаться на консультацию',
            }

          case Indi.Events.Spheres.HEALTH:
            return {
              title: 'Здоровье в приоритете',
              text: 'Консультация со специалистом поможет вам разработать план по улучшению качества жизни',
              btnTitle: 'Записаться на консультацию',
            }
        }
      }
    }

    if (diagnostic.includes(sphere)) {
      if (sent) {
        return {
          title: 'Заявка на диагностику отправлена!',
          text: 'Менеджер Chronos скоро с вами свяжется',
        }
      } else {
        return {
          title: 'Решите свой вопрос с помощью диагностики',
          text: 'Быстрая диагностика для вашего внутреннего запроса за 839 рублей',
          btnTitle: 'Отправить заявку',
        }
      }
    }
  }

  const getDiagnosticBackground = (sphere: Indi.Events.Spheres) => {
    switch(sphere) {
      case Indi.Events.Spheres.COMMUNICATION:
        return CommunicationBg
      case Indi.Events.Spheres.EMOTIONS:
        return EmotionsBg
      case Indi.Events.Spheres.TRAVEL:
        return TravelBg
    }
  }

  const getConsultationBackground = (sphere: Indi.Events.Spheres) => {
    if (consultation.includes(sphere)) {
      return eventsOptions[sphere].bgColor
    }
  }

  const getBannerId = (sphere: Indi.Events.Spheres) => {
    switch (sphere) {
      case Indi.Events.Spheres.COMMUNICATION:
        return Indi.IndiBanners.BannersId.EVENT_DIAGNOSTIC_COMMUNICATION
      case Indi.Events.Spheres.EMOTIONS:
        return Indi.IndiBanners.BannersId.EVENT_DIAGNOSTIC_EMOTIONS
      case Indi.Events.Spheres.TRAVEL:
        return Indi.IndiBanners.BannersId.EVENT_DIAGNOSTIC_TRAVEL
      case Indi.Events.Spheres.LOVE:
        return Indi.IndiBanners.BannersId.EVENT_CONSULTATION_LOVE
      case Indi.Events.Spheres.FINANCE:
        return Indi.IndiBanners.BannersId.EVENT_CONSULTATION_FINANCE
      case Indi.Events.Spheres.CAREER:
        return Indi.IndiBanners.BannersId.EVENT_CONSULTATION_CAREER
      case Indi.Events.Spheres.HEALTH:
        return Indi.IndiBanners.BannersId.EVENT_CONSULTATION_HEALTH
    }
  }

  const onSubmit = () => {
    const bannerId = getBannerId(props.sphere)
    const type = diagnostic.includes(props.sphere) ? Indi.IndiLeads.Leads.DIAGNOSTICS : Indi.IndiLeads.Leads.PROFILE_CONSULTATION
    const sphere = props.sphere as Indi.IndiLeads.Spheres

    if (!userId || !bannerId) return

    userId && store.banners.putBanner(userId, bannerId)
    store.banners.postLeads(type, sphere)
    setSent(true)
  }

  const onClickHandler = () => {
    gtmSend({ 'event': `indi_adv_events_${diagnostic.includes(props.sphere) ? 'diag' : 'cons'}_${props.sphere}`, 'user_id': userId ? userId : '' })

    if (!phoneNumber) {
      openConsultationSignUp(onSubmit, props.sphere as Indi.IndiLeads.Spheres)
      return
    }

    onSubmit()
  }

  if (!getBannerContent(props.sphere)) return null

  return (
    <StyledBanner
      title={getBannerContent(props.sphere)!.title}
      text={getBannerContent(props.sphere)!.text}
      buttonContent={getBannerContent(props.sphere)?.btnTitle && <StyledButtonContent title={getBannerContent(props.sphere)!.btnTitle!} />}
      onClick={onClickHandler}
      background={getConsultationBackground(props.sphere)}
      backgroundImg={getDiagnosticBackground(props.sphere)}
      buttonColor={diagnostic.includes(props.sphere) ? props.sphere as TButtonColor : undefined}
    />
  )
})

const StyledBanner = styled(Banner)<{backgroundImg?: string}>`
  color: var(--text-primary);

  .title {
    width: 100%;
  }

  .banner_button {
    width: fit-content;
  }

  ${p => p.backgroundImg && css`
    background: url(${p.backgroundImg}) no-repeat right bottom / cover;
  `}
`

const StyledButtonContent = styled(ButtonContent)`
  padding-right: 0;
`
