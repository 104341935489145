import { Indi } from "../../libs"
import { observer } from "mobx-react-lite"
import { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { MainPadding } from "../../components/layout/elements"
import { Button } from "../../components/ui/Button/Button"
import { TextInput } from "../../components/ui/TextInput"
import { formatPhoneNumberForInput } from "../../helpers/phone"
import { gtmSend } from "../../helpers/url"
import { popupsStore } from "../../store/Popups"
import store from "../../store/Store"
import { diagnostic } from "../my-day/components/event/EventBanner/EventBanner"

interface ConsultationSignUpProps {
  onSubmit(): void
  sphere: Indi.IndiLeads.Spheres
}

const WINDOW_ID_CONSULTATION_SIGN_UP = 'WINDOW_ID_CONSULTATION_SIGN_UP'
const CONSULTATION_SIGN_UP_HEIGHT = '-360px'

export const openConsultationSignUp = (onSubmit: () => void, sphere: Indi.IndiLeads.Spheres) => {
  popupsStore.open(
    WINDOW_ID_CONSULTATION_SIGN_UP,
    <ConsultationSignUp onSubmit={onSubmit} sphere={sphere}/>,
    '',
    undefined,
    undefined,
    CONSULTATION_SIGN_UP_HEIGHT,
  )
}

export const ConsultationSignUp = observer((props: ConsultationSignUpProps) => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [phoneError, setPhoneError] = useState<string>('');

  const gtmInputRef = useRef(false)

  const profile = store.userProfile.profile
  const userId = store.sessionData?.id

  useEffect(() => {
    if (!profile) {
      store.userProfile.getProfile()
    }
  }, [profile])

  const onClose = () => {
    popupsStore.close(WINDOW_ID_CONSULTATION_SIGN_UP)
  }

  const onChangeHandler = (value: string) => {
    const formattedValue = formatPhoneNumberForInput(value)
    setPhoneNumber(formattedValue ?? '')
    setPhoneError('')

    if (!gtmInputRef.current) {
      gtmSend({ 'event': `indi_adv_events_${diagnostic.includes(props.sphere) ? 'diag' : 'cons'}_input_number`, 'user_id': userId ? userId : '' })
      gtmInputRef.current = true
    }
  }

  const checkPhoneNumber = () => {
    if (!phoneNumber) {
      setPhoneError('Введите номер')
      return true
    }

    if (phoneNumber.length < 18) {
      setPhoneError('Неправильный формат номера')
      return true
    }

    return false
  }

  const onSubmit = () => {
    gtmSend({ 'event': `indi_adv_events_${diagnostic.includes(props.sphere) ? 'diag' : 'cons'}_send`, 'user_id': userId ? userId : '' })

    if (checkPhoneNumber()) return

    if (!profile) return

    const newProfile = {
      ...profile,
      phoneNumber,
    }

    store.userProfile.updateProfile(newProfile)
    props.onSubmit()
    onClose()
  }

  const onCloseHandler = () => {
    gtmSend({ 'event': `indi_adv_events_${diagnostic.includes(props.sphere) ? 'diag' : 'cons'}_close`, 'user_id': userId ? userId : '' })
    onClose()
  }

  return (
    <Container>
      <div className={'title'}>Запись на консультацию</div>
      <div className={'text'}>Укажите свой телефон, чтобы мы могли связаться с вами и уточнить детали для вашей записи</div>

      <TextInput
        value={phoneNumber}
        onChange={onChangeHandler}
        title={'Ваш телефон'}
        type={'tel'}
        placeholder={'+_ (___) ___-__-__'}
        error={phoneError}
      />

      <Buttons>
        <Button onClick={onSubmit}>Отправить</Button>
        <Button onClick={onCloseHandler} color="gray">Закрыть</Button>
      </Buttons>
    </Container>
  )
})

const Container = styled(MainPadding)`
  .title {
    font-size: 1.25rem;
    font-weight: 500;
  }

  .text {
    font-size: 0.875rem;
    color: var(--text-secondary);
    margin: 0.5rem 0 1.25rem 0;
  }
`

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
